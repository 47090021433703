<!-- 市场详情页面通用组件 -->
<script setup>
import {
  ref,
  reactive,
  provide,
  inject,
  onMounted,
  defineProps,
  computed,
  onBeforeUnmount,
  watchEffect
} from "vue";
import { useAppStore } from "@store/appStore";
import { useRoute, useRouter } from "vue-router";
import { handleCopy } from "@/utils/tools.js";
import AttrsComp from "./AttrsComp.vue";
import { useWrite } from "@/hooks/useAction";
import { pickCardType, setShowTransferPop } from "../../useCardPackage.js";
import { plusXing, getTradeTypeCoin } from "@/utils/tools";
import useMarketStore from "@store/cattleMarketStore";
import useCattleStore from "@contractStore/cattleStore";
import useCattleMineStore from "@contractStore/cattleMintStore";
import useCattleBadgeStore from "@contractStore/cattleBadgeStore";
import usePlanetStore from "@contractStore/planetStore";
import useBlindBoxStore from "@contractStore/blindBoxStore";
import useOATMintStore from "@contractStore/oatMintStore.js";
import usePlanetLeaseStore from "@contractStore/planetLeaseStore";
import {
  FRAGMENT_CONT,
  STAR_INFO_CONT,
  BVT_TOKEN_CONT,
  OPEN_BINANCE_BOX_CONT
} from "@/contracts/polygonAddress";
import blindBoxObj from "@/contracts/blindBox";
import CoinToken from "@/contracts/CoinToken.js";
import itemsObj from "@/contractsApi/polygon/ItemVoucher";
import { ElMessage } from "element-plus";
import $load from "@cps/GlobalLoading";
import {
  showPop,
  showOpenBoxPop,
  setShowPop,
  setShowOpenBox
} from "@cps/PopUp/usePop.js";
import {
  hasAllowed,
  useContent,
  msg,
  boxImg,
  isClickConfirm,
  _toTwo
} from "./useContent";
import OpenBoxPop from "@cps/PopUp/OpenBoxPop.vue";
import Pop from "@cps/PopUp/Pop.vue";
import { useCardDetail } from "../useCardDetail"; // 卡包详情
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { CHAINID_HEX, TRADE_TYPE } from "@/constants";
import i18n from "@/utils/i18n";
const $t = i18n.global.t;

dayjs.extend(duration);
const appStore = useAppStore();
const $router = useRouter();
const $route = useRoute();
const bvtToken = new CoinToken(BVT_TOKEN_CONT);
const cattleStore = useCattleStore();
const cattleBadgeStore = useCattleBadgeStore();
const packageStore = useMarketStore();
const planetStore = usePlanetStore();
const blindBoxStore = useBlindBoxStore();
const oatMintStore = useOATMintStore();
const planetLeaseStore = usePlanetLeaseStore();
const cattleMineStore = useCattleMineStore();
planetLeaseStore.createContract();
cattleStore.createContract();
oatMintStore.createContract();
cattleMineStore.createContract()

const { handleApproveforAll, handleOpen } = useContent();
const {
  hasParents,
  cardName,
  cardImg,
  cardDesc,
  contractAddr,
  cattleAttr,
  fatherAttr,
  motherAttr,
  cattleDeadLine,
  isAdultCattle,
  setBlindBoxParents,
  setCattleDetailInfo
} = useCardDetail();

const showCate = $route.query.cate; // 侧边栏的分类 planet hero cattle blindbox lease cattleBadge other
const showType = $route.query.type; // 具体内容分类
const tokenId = $route.query.id; // tokenid
// 分发星球属性
provide(
  "planetAttr",
  computed(() => {
    if (showCate === "lease") {
      return planetLeaseStore.planetInfo;
    } else {
      return planetStore.planetInfo;
    }
  })
);
const isPresident = computed(() => planetLeaseStore.leaseInfo.isPresident); // 是否是会长
const isPlanetOwner = computed(() => planetLeaseStore.leaseInfo.isOwner); // 是否是星球持有者
const isPlanetTenant = computed(() => planetLeaseStore.leaseInfo.isTenant); // 是否是星球租客
const planetRentTime = computed(() => planetLeaseStore.leaseInfo.rentTime); // 星球到期时间
const planetRentAmount = computed(() => planetLeaseStore.leaseInfo.rentAmount); // 星球租金
const usdtPrices = ref(0); // 等价的 USDT

const countDown = ref(null);
const checkTimer = ref(null);

// 牛的死亡时间
const cattleDeadTime = computed(() => {
  if (!cattleDeadLine.value) return null;
  const days = dayjs(cattleDeadLine.value).diff(dayjs(), "day");
  if (days < 1) {
    setCountDown();
    return countDown.value;
  } else {
    return `${days} ${$t("blindBox.3")}`;
  }
});

/**
 * 小於一天的時候展示倒計時
 */
function setCountDown() {
  checkTimer.value = setInterval(() => {
    const now = new Date().getTime(); // 当前时间戳

    const diffTime = dayjs.duration(cattleDeadLine.value - now);
    const hours = diffTime.hours(); //小时
    const minutes = diffTime.minutes(); //分钟
    const seconds = diffTime.seconds(); //秒
    countDown.value = `${_toTwo(hours)}:${_toTwo(minutes)}:${_toTwo(seconds)}`;
  }, 1000);
}

// 租期到期时间戳
const rentCountDown = reactive({
  days: "00",
  hours: "00",
  minutes: "00",
  seconds: "00"
});

// 星球租期倒计时
const isRentEnd = ref(false);
const rentEndTime = ref("");
const checkRentEndTimer = ref(null);
checkRentEndTimer.value = setInterval(async () => {
  const now = Date.now(); // 当前时间戳
  if (now >= planetRentTime.value) {
    isRentEnd.value = true;
    return;
  }

  const diffTime = dayjs.duration(planetRentTime.value - now);

  // const days = Math.floor((planetRentTime.value - now) / (24 * 3600 * 1000)); //天
  const days = Math.floor(diffTime.asDays()); //天
  const hours = diffTime.hours(); //小时
  const minutes = diffTime.minutes(); //分钟
  const seconds = diffTime.seconds(); //秒

  // 星球租期结束时间
  rentCountDown.days = days;
  rentCountDown.hours = _toTwo(hours);
  rentCountDown.minutes = _toTwo(minutes);
  rentCountDown.seconds = _toTwo(seconds);
  // console.log('time..', times, isStart.value);
}, 1000);

onBeforeUnmount(() => {
  clearInterval(checkTimer.value);
  clearInterval(checkRentEndTimer.value);
});

// 判断有没有父母
const hasParent = computed(() => {
  if (!["cattle", "blindBox"].includes(showType)) {
    return false;
  }

  if (hasParents.value) {
    return true;
  } else {
    return false;
  }
});

/**
 * 初始化，获取盲盒是否授权-》开盲盒
 */
const itemsAmount = ref(0);
async function init() {
  console.log("init", showCate, showType);
  try {
    // 牛牛详情
    if (showCate === "hero" || showCate === "cattle") {
      const ownerAddr = await cattleStore.getOwnerAddr(tokenId);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        await setCattleDetailInfo(tokenId);
      } else {
        $router.push({ name: "cardPackage" });
      }
    }

    if (showCate === "planet") {
      // 星球
      const ownerAddr = await planetStore.getOwnerAddr(tokenId);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        await planetStore.getPlanetInfo(tokenId, showType);
      } else {
        $router.push({ name: "cardPackage" });
      }
    }

    // 星球出租
    if (showCate === "lease") {
      await planetLeaseStore.getPlanetInfo(tokenId, showType);
      console.log(planetLeaseStore.leaseInfo);
      // 获取星球租赁的租金及信息
      await packageStore.getCurrency();
      await planetLeaseStore.getViewInfo();
      const amt =
        planetRentAmount.value === ""
          ? 0
          : planetRentAmount.value.split(",").join("");
      hasTokenAllowance.value = await bvtToken.allow(
        appStore.defaultAccount,
        STAR_INFO_CONT.address,
        amt
      );

      usdtPrices.value = packageStore.countingPrice({
        types: 1,
        amount: amt
      });
      // 到期時間
      rentEndTime.value = dayjs(planetRentTime.value).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    // 游戏初始盲盒
    // if (showCate === 'blindBox' && appStore.ethObj.chainId === '0x89') {
    if (showCate === "blindBox" && appStore.ethObj.chainId === CHAINID_HEX) {
      const ownerAddr = await blindBoxStore.getOwnerAddr(tokenId);
      if (ownerAddr.toUpperCase() === appStore.defaultAccount.toUpperCase()) {
        blindBoxStore.getBoxInfo(tokenId, showType);
        if (showType === "blindBox") {
          const pids = await blindBoxStore.checkParents(tokenId);
          setBlindBoxParents(pids);
        }
      } else {
        $router.push({ name: "cardPackage" });
      }
    }
    // IGO 盲盒是否授权开盲盒合约
    if (showCate === "blindBox") {
      hasAllowed.value = await blindBoxStore.isApproveAll(
        appStore.defaultAccount,
        showType
      );
    }
    // 判断币安盲盒是否授权开盲盒合约
    if (showType === "binance_box" && appStore.ethObj.chainId === "0x38") {
      hasAllowed.value = await blindBoxStore.isApproveAll(
        appStore.defaultAccount,
        showType,
        OPEN_BINANCE_BOX_CONT.address
      );
    }
    // halo 宝箱开出的碎片
    if (showType === "shard") {
      const shard = new blindBoxObj(FRAGMENT_CONT);
      const num = await shard.contract.balanceOf(appStore.defaultAccount, 1);
      itemsAmount.value = +num;
    }
    // 游戏道具的兑换券
    if (showType === "items") {
      const items = new itemsObj();
      const num = await items.contract.balanceOf(
        appStore.defaultAccount,
        tokenId
      );
      itemsAmount.value = +num;
    }
    // 牛牛徽章
    if (showCate === "cattleBadge") {
      let hasBadge = await cattleBadgeStore.getOwnerHasBadge(tokenId);
      if (hasBadge) {
        await cattleBadgeStore.getCattleInfo(tokenId);
      } else {
        $router.push({ name: "cardPackage" });
      }
    }

    // oat bv
    if (showType === "oat") {
      await oatMintStore.getCardInfo(tokenId);
    } else if (
      showType === "Bovine-Hero" ||
      showType === "Cattle" ||
      showType === "Items-Pack" ||
      showType === "Skins-Pack"
    ) {
      blindBoxStore.getBoxInfo(tokenId, showType);
    } else {
      blindBoxStore.getBoxInfo(tokenId, showType);
    }
  } catch (error) {
    console.log(error);
  }
}

watchEffect(async () => {
  if (appStore.defaultAccount == null) return;
  await init();
});

// 返回交易市场
function backtoPackage() {
  $router.back();
}

/**
 * 是否展示出租按钮
 */
const isShowRentBtn = computed(() => {
  return isPresident.value && !isPlanetOwner.value && !isPlanetTenant.value;
});

/**
 * 出租下架按钮
 */
const isShowCancelRentBtn = computed(() => {
  return isPresident.value && isPlanetOwner.value && isRentEnd.value;
});

/**
 * 租客状态
 */
const isShowCutDownBtn = computed(() => {
  return isPresident.value && isPlanetTenant.value && !isRentEnd.value;
});

/**
 * 租客到期状态
 */
const isShowRentingBtn = computed(() => {
  return isPresident.value && isPlanetTenant.value;
});

/**
 * 是否展示续租状态
 */
const isShowPayRentBtn = computed(() => {
  return isPresident.value && isPlanetTenant.value && isRentEnd.value;
});

/**
 * 是否展示售卖按钮
 * 如果是卖牛，只能卖创世牛或者未成年牛
 */
const isShowSellBtn = computed(() => {
  const allowType = [
    "halo_box", // 光轮宝箱
    "mark_box", // 盲盒
    "creation_ticket", //创世牛牛
    "normal_ticket", // 普通牛牛
    "homeland_ticket", // 家园星球
    "genland_ticket", // 拓荒星球
    "shard", // 碎片
    "items", // 道具
    "lease" // 星球租賃
  ];
  if (showCate === "cattle") {
    // 普通牛未成年牛
    return isAdultCattle.value ? false : true;
  } else if (allowType.includes(showType) || showCate === "cattleBadge") {
    // 牛牛徽章暂时未做出售
    return false;
  } else {
    return true;
  }
});

/**
 * 是否展示开启按钮
 */
const isShowOpenBtn = computed(() => {
  const allowType = ["Mystery-Box", "halo_box", "mark_box", "binance_box"];
  if (allowType.includes(showType)) {
    return true;
  } else {
    return false;
  }
});

/**
 * 是否展示转账按钮
 */
const isShowTransferBtn = computed(() => {
  const allowType = [
    "Mystery-Box",
    "Bovine-Hero",
    "Cattle",
    "Items-Pack",
    "Skins-Pack",
    "halo_box",
    "mark_box",
    "creation_ticket",
    "normal_ticket",
    "homeland_ticket",
    "genland_ticket",
    "shard",
    "items",
    "binance_box"
  ];
  if (allowType.includes(showType) || $route.query.cate === "hero") {
    return true;
  } else {
    return false;
  }
});

/**
 * 是否展示背景
 */
const isShowBgImg = computed(() => {
  const allowType = [
    "Mystery-Box",
    "Bovine-Hero",
    "Cattle",
    "Items-Pack",
    "Skins-Pack"
  ];
  if (allowType.includes(showType)) {
    return false;
  } else {
    return true;
  }
});

/**
 * 图片是否有空隙
 */
const isShowGap = computed(() => {
  const allowType = ["items"];
  if (allowType.includes(showType)) {
    return true;
  } else {
    return false;
  }
});

/**
 * 授权 BVT
 */
const bvtLoading = ref(false);
const hasTokenAllowance = ref(false);
async function setApproveBVT(forceApprove = false) {
  if (!forceApprove & hasTokenAllowance.value) return;
  bvtLoading.value = true;
  try {
    await bvtToken.auth(STAR_INFO_CONT.address);
    hasTokenAllowance.value = await bvtToken.allow(
      appStore.defaultAccount,
      STAR_INFO_CONT.address
    );
    ElMessage({
      message: $t("msg.3"),
      type: "success"
    });
  } catch (error) {
    let info = error?.reason || error?.message || error?.data?.message;
    // 点击了拒绝信息
    if (info?.includes?.("User denied transaction")) {
      info = "User denied transaction signature.";
    }
    // 点击了拒绝信息
    if (error?.includes?.("cancel")) {
      info = "User denied transaction signature.";
    }
    // 避免信息太长看懵用户
    info = String(info).length > 100 ? $t("msg.4") : info;
    ElMessage({
      message: info,
      type: "error"
    });
  }
  bvtLoading.value = false;
}

/**
 * 星球出租下架
 */
const cancelLoading = ref(false);
const [cancelRent] = useWrite(async () => {
  cancelLoading.value = true;
  try {
    const { status } = await planetLeaseStore.leaseContract.cancelRent();
    if (status) {
      await planetLeaseStore.getViewInfo();
      rentEndTime.value = dayjs(planetRentTime.value).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
  } catch {
    cancelLoading.value = false;
  }
  cancelLoading.value = false;
});

/**
 * 星球续租
 */

const payRentLoading = ref(false);
const [payRent] = useWrite(async () => {
  payRentLoading.value = true;
  if (!hasTokenAllowance.value) {
    await setApproveBVT();
    payRentLoading.value = false;
    return;
  }
  try {
    const resp = await planetLeaseStore.leaseContract.payRent();
    console.log("payRent resp", resp, resp.message.reason);
    if (resp?.status) {
      await planetLeaseStore.getViewInfo();
      rentEndTime.value = dayjs(planetRentTime.value).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }
    console.log(resp?.message?.reason);
    if (
      resp?.message?.reason.indexOf("transfer amount exceeds allowance") > -1
    ) {
      setApproveBVT(true);
      // bvtToken.auth(STAR_INFO_CONT.address);
    }
  } catch (error) {
    // console.log('payRent error',error)
    payRentLoading.value = false;
    planetLeaseStore.getViewInfo();
  }
  payRentLoading.value = false;
  // location.reload()
});

/**
 * 跳转转账
 */
function handleTrans() {
  if (appStore.curDevice !== "phone") {
    setShowTransferPop(true);
  } else {
    $router.push({
      name: "transfer",
      query: {
        id: tokenId,
        type: $route.query.type,
        cate: $route.query.cate
      }
    });
  }
}

/**
 * 跳转挂售页
 */
async function handleSell() {
  //   前端点击出售按钮，请求合约白名单的接口，判断白名单是否开启，
  // 开启时：判断当前地址是否是白名单地址
  //              是：进入到设置挂单页面
  //              否：提示  暫未開放出售，請您敬請期待！
  //              英文提示  Not yet open for sale, please stay tuned
  // 未开启时：进入到设置挂单页面

  const isValid = await cattleMineStore.verifyWhitelist();
  console.log('isValid', isValid)
  if (!isValid) {
    ElMessage({
      message: $t("msg.74"),
      type: "warning"
    });
    return;
  }
  if (
    showType === "Mystery-Box" ||
    showType === "Bovine-Hero" ||
    showType === "Cattle" ||
    showType === "Items-Pack" ||
    showType === "Skins-Pack"
  ) {
    window.open(
      "https://tofunft.com/collection/bovineverse-igo-mystery-box/items"
    );
  } else if (showType === "oat") {
    window.open("https://tofunft.com/collection/galaxy-oat/items");
  } else if (showType === "binance_box") {
    window.open("https://www.binance.com/en/markets");
  } else {
    $router.push({
      name: "setListing",
      query: {
        id: tokenId,
        cate: $route.query.cate,
        type: $route.query.type
      }
    });
    // ElMessage({
    //   type: 'warning',
    //   message: $t('msg.32'),
    // });
  }
}

/**
 * 开启盲盒弹窗
 */
function handlePop() {
  if (showType === "halo_box") {
    $router.push({
      name: "cattleMartEvent"
    });
    return;
  }
  if (showType === "mark_box") {
    $router.push({
      name: "mergeFragments"
    });
    return;
  }
  setShowOpenBox(true);
}

/**
 * 跳转出租頁面
 */
function handleRent() {
  $router.push({
    name: "planetLease",
    query: {
      id: tokenId,
      cate: $route.query.cate,
      type: $route.query.type
    }
  });
}

onMounted(() => {
  handleCopy();
});
</script>

<template>
  <div class="detail-wrap">
    <div class="detail-container">
      <div class="back">
        <div @click="backtoPackage">
          <i class="iconfont icon-simple-arrow"></i>
          <span style="font-size: 0.34rem">{{ $t('dialog.3') }}</span>
        </div>
      </div>

      <div class="card-container">
        <!-- 牛牛卡牌 -->
        <div :class="['img-wrap']">
          <!-- <img :src="cardImg" alt="" class="card-img" /> -->
          <div class="badge-img-container" v-if="showCate === 'cattleBadge'">
            <div :class="`badge-img ${showType}`"></div>
          </div>
          <img
            v-else
            :src="cardImg"
            alt
            :class="['card-img', isShowBgImg ? 'bg-img' : '', isShowGap ? 'card-padding' : '']"
          />
          <div class="contract-container" v-show="appStore.curDevice == 'pc'">
            <!-- 合约地址 -->
            <div class="contract-content">
              <div>{{ $t('detail.18') }}</div>
              <div>
                <span>{{ plusXing(contractAddr, 8, 8) }}</span>
                <i :data-clipboard-text="contractAddr" class="iconfont icon-cpy cpy-btn"></i>
              </div>
            </div>

            <!-- token id -->
            <div class="token-id" v-if="showCate !== 'cattleBadge'">
              <div style="margin-top: 0.1rem">{{ $t('detail.19') }}</div>
              <div>
                <span>{{ tokenId }}</span>
              </div>
            </div>
            <!-- Number -->
            <div class="token-id" v-if="itemsAmount">
              <div style="margin-top: 0.1rem">{{ $t('detail.26') }}</div>
              <div>
                <span>{{ itemsAmount }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-wrap">
          <header>
            <!-- 牛牛 -->
            <template v-if="cardName">
              <span style="font-size: 0.36rem">{{ cardName }}</span>
              <i
                v-if="showCate === 'cattle' || showCate === 'hero'"
                :class="['iconfont', cattleAttr[0]?.gender === 1 ? 'icon-nan' : 'icon-nv']"
              ></i>
              <div
                class="lease-type"
                v-show="isPlanetOwner && showCate === 'lease'"
              >{{ $t('detail.64') }}</div>

              <div
                :class="['lease-time', isRentEnd ? 'rentEnd' : '']"
                v-show="isPlanetTenant && showCate === 'lease'"
              >
                {{
                isRentEnd
                ? $t('airdrop.9')
                : `${rentCountDown.days} ${$t('lazio.30')} ${rentCountDown.hours} ${$t(
                'lazio.31'
                )} ${rentCountDown.minutes} ${$t('lazio.32')} ${rentCountDown.seconds} ${$t(
                'lazio.33'
                )}`
                }}
              </div>
            </template>
          </header>
          <div class="dead-time-wrap" v-show="cattleDeadLine && showCate === 'cattle'">
            <div class="label">{{ $t('detail.61') }}</div>
            {{ cattleDeadTime }}
          </div>
          <!-- 属性 -->
          <AttrsComp :showType="showCate" :attrs="showType == 'blindBox' ? null : cattleAttr" />

          <!-- 描述 -->
          <div
            v-if="showCate !== 'cattleBadge'"
            :class="['desc-container', showCate == 'blindBox' ? 'blindBox' : '']"
          >
            <div class="label">{{ $t('detail.20') }}</div>
            <div>{{ cardDesc }}</div>
            <div v-show="showType == 'blindBox'" class="tip">{{ $t('detail.62') }}</div>
          </div>
          <!-- card button -->
          <div class="btn-wrap" v-show="appStore.curDevice === 'pc' && showCate !== 'lease'">
            <button class="btn" v-show="isShowSellBtn" @click="handleSell">{{ $t('detail.15') }}</button>
            <button class="btn" v-show="isShowOpenBtn" @click="handlePop">{{ $t('detail.16') }}</button>
            <button
              class="btn"
              v-show="isShowTransferBtn"
              @click="handleTrans"
            >{{ $t('detail.17') }}</button>
          </div>
          <!-- lesae content -->
          <div class="lease-wrap" v-show="appStore.curDevice === 'pc' && showCate === 'lease'">
            <div class="coin-wrap" v-show="isShowRentingBtn">
              <span class="coin">{{ `${planetRentAmount} ${TRADE_TYPE[1]}` }}</span>
              <span class="val">{{ `≈ $ ${usdtPrices}` }}</span>
            </div>
            <div class="lease-btn-wrap">
              <button class="btn" v-show="isShowRentBtn" @click="handleRent">{{ $t('detail.63') }}</button>
              <button
                class="btn"
                v-show="isShowCancelRentBtn"
                v-loading="cancelLoading"
                @click="cancelRent"
              >{{ $t('detail.65') }}</button>
              <button
                :class="['btn', isShowCutDownBtn ? '' : 'cutdown']"
                v-show="isShowRentingBtn"
              >{{ $t('detail.66') }}</button>
              <button
                class="btn"
                v-show="isShowRentingBtn"
                v-loading="payRentLoading"
                @click="payRent"
              >{{ hasTokenAllowance ? $t('detail.67') : $t('dialog.8') }}</button>
            </div>
          </div>
          <!-- <div class="tip-wrap" v-show="appStore.curDevice === 'pc' && isShowRentingBtn">
            <div class="tip">{{ `${$t('detail.68')} ${rentEndTime}` }}</div>
            <div class="tip">{{ $t('detail.69') }}</div>
          </div>-->
        </div>
      </div>

      <footer
        v-show="appStore.curDevice !== 'pc'"
        :style="{marginTop: showCate === 'cattleBadge'?'0.5rem':'0'}"
      >
        <div class="contract-container">
          <!-- 合约地址 -->
          <div class="contract-content">
            <div>{{ $t('detail.18') }}</div>
            <div>
              <span>{{ plusXing(contractAddr, 8, 8) }}</span>
              <i :data-clipboard-text="contractAddr" class="iconfont icon-cpy cpy-btn"></i>
            </div>
          </div>

          <!-- token id -->
          <div class="token-id">
            <div style="margin-top: 0.1rem">{{ $t('detail.19') }}</div>
            <div>
              <span>{{ tokenId }}</span>
            </div>
          </div>

          <!-- Number -->
          <div class="token-id" v-if="itemsAmount">
            <div style="margin-top: 0.1rem">{{ $t('detail.26') }}</div>
            <div>
              <span>{{ itemsAmount }}</span>
            </div>
          </div>
        </div>
        <!-- mob card btn -->
        <div class="btn-wrap" v-show="showCate !== 'lease'">
          <button class="btn" v-show="isShowSellBtn" @click="handleSell">{{ $t('detail.15') }}</button>
          <button class="btn" v-show="isShowOpenBtn" @click="handlePop">{{ $t('detail.16') }}</button>
          <button class="btn" v-show="isShowTransferBtn" @click="handleTrans">{{ $t('detail.17') }}</button>
        </div>

        <!-- mob lease content -->
        <div class="lease-wrap" v-show="showCate === 'lease'">
          <div class="coin-wrap" v-show="isShowRentingBtn">
            <span class="coin">{{ `${planetRentAmount} ${TRADE_TYPE[1]}` }}</span>
            <span class="val">{{ `≈ $ ${usdtPrices}` }}</span>
          </div>
          <div class="lease-btn-wrap">
            <button class="btn" v-show="isShowRentBtn" @click="handleRent">{{ $t('detail.63') }}</button>
            <button
              class="btn"
              v-show="isShowCancelRentBtn"
              v-loading="cancelLoading"
              @click="cancelRent"
            >{{ $t('detail.65') }}</button>
            <button
              :class="['btn', isShowCutDownBtn ? '' : 'cutdown']"
              v-show="isShowRentingBtn"
            >{{ $t('detail.66') }}</button>
            <button
              class="btn"
              v-show="isShowRentingBtn"
              v-loading="payRentLoading"
              @click="payRent"
            >{{ hasTokenAllowance ? $t('detail.67') : $t('dialog.8') }}</button>
          </div>
          <div class="tip-wrap" v-show="isShowRentingBtn">
            <div class="tip" v-if="!isRentEnd">{{ `${$t('detail.68')} ${rentEndTime}` }}</div>
            <div class="tip" v-if="!isRentEnd">{{ $t('detail.69') }}</div>
          </div>
        </div>
      </footer>

      <!-- 父母属性 -->
      <div class="parent-attrs-container" v-if="hasParent">
        <div class="head">{{ $t('detail.35') }}</div>
        <template v-if="fatherAttr !== null">
          <div class="father-attr">
            <div class="title">{{ $t('detail.36') }}</div>
            <AttrsComp :showType="showCate" :attrs="fatherAttr" />
          </div>
        </template>

        <template v-if="motherAttr !== null">
          <div class="mother-attr">
            <div class="title">{{ $t('detail.37') }}</div>
            <AttrsComp :showType="showCate" :attrs="motherAttr" />
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- 开盲盒弹窗 -->
  <OpenBoxPop
    :isApprove="hasAllowed"
    :isStake="isClickConfirm"
    @approve="handleApproveforAll"
    @open="handleOpen"
  ></OpenBoxPop>
  <!-- 展示用彈窗 -->
  <Pop :title="msg">
    <template #content>
      <div class="img-wrap">
        <div class="img" :style="{ backgroundImage: `url(${boxImg})` }" v-show="boxImg !== ''"></div>
      </div>
    </template>
  </Pop>
</template>

<style lang="scss" scoped>
.detail-wrap {
  color: #fff;
  width: 100%;
  min-height: calc(100vh - $pcTopBarHeight);
  padding: 0 0 1.16rem;
  border-top: solid 0.1px transparent; // 为了解决margin塌陷

  // @include nftImgBg;

  @media (max-width: $pad) {
    min-height: calc(100vh - $mobTopBarHeight);
    padding: 0 0.4rem 1.16rem;
  }

  .detail-container {
    // margin: 0 auto;
    @media (min-width: $pad) {
      width: 12rem;
    }
  }

  .back {
    margin: 0.5rem 0 0.28rem;
    @include flexPos(flex-start, center);
    font-size: 0.34rem;
    cursor: pointer;

    > div {
      @include flexPos(flex-start, center);
    }

    .iconfont {
      margin-right: 0.1rem;
      font-size: 0.24rem;
    }
  }
}

header {
  font-size: 0.36rem;
  font-weight: 500;
}

.title {
  font-size: 0.22rem;
}

.card-padding {
  padding: 0.6rem;
}

// 卡牌信息
.card-container {
  display: flex;
  width: 100%;

  @media (max-width: $pad) {
    @include flexPos(center, flex-start);
    flex-direction: column;
  }

  @media (max-width: $phone) {
    @include flexPos(center);
  }

  .bg-img {
    background-image: linear-gradient(to bottom, transparent, #5a25b1 100%);
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-radius: 10px;
    margin-left: 0.01rem;
  }
  .badge-img-container {
    @include flexPos(center, center);
    width: 3.45rem !important;
    height: 3.45rem !important;
    background-image: linear-gradient(to bottom, transparent, #5a25b1 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    margin-left: 0.01rem;
    margin-bottom: 0.31rem;
    .badge-img {
      width: 106px;
      height: 106px;
      background: url("../../../../assets/img/common/icon.png") no-repeat;
      &.Bronze {
        background-position: 0 -106px * 6;
      }
      &.Silver {
        background-position: -106px * 2 -106px * 3;
      }
      &.Gold {
        background-position: -106px -106px * 3;
      }
      &.Platinum {
        background-position: -106px * 2 -106px * 1;
      }
      &.Diamond {
        background-position: -106px * 3 -106px * 3;
      }
      &.Master {
        background-position: -106px * 1 -106px * 7;
      }
    }
  }

  .img-wrap {
    @include flexPos(flex-start, center);
    width: 3.45rem !important;
    // height: 4.85rem !important;
    margin-bottom: 0.31rem;
    @media (min-width: $pad) {
      margin-right: 0.72rem;
    }
    .card-img {
      box-sizing: border-box;
      width: 3.45rem;
    }
  }

  .info-wrap {
    @include flexPos(flex-start, flex-start);
    flex-direction: column;
    border-radius: 6px;
    width: 100%;

    @media (min-width: $phone) and (max-width: $pad) {
      :deep(.basic-attr-wrap) {
        width: 45%;
      }
    }

    header {
      margin-right: 0.17rem;
      @include flexPos(space-around);

      .iconfont {
        font-size: 0.26rem;
        margin-left: 0.15rem;
      }

      .lease-type {
        color: #fff;
        width: 1.22rem;
        height: 0.38rem;
        border-radius: 0.59rem;
        font-size: 0.2rem;
        font-weight: 500;
        margin-left: 0.4rem;
        @include flexPos(center);
        background-color: #1ccb62;
      }

      .lease-time {
        color: #fff;
        // width: 2.46rem;
        height: 0.38rem;
        padding: 0 0.2rem;
        border-radius: 0.59rem;
        font-size: 0.2rem;
        font-weight: 500;
        margin-left: 0.4rem;
        @include flexPos(center);
        background-color: #1ccb62;
      }
      .rentEnd {
        background-color: #4e4e4e !important;
      }

      @media (max-width: $phone) {
        width: 100%;
        font-size: 0.6rem;
        justify-content: center;

        .iconfont {
          font-size: 0.45rem;
        }
      }
    }
  }

  .dead-time-wrap {
    font-size: 0.22rem;
    // font-family: 'pingfang-regular';
    // @include -media($pc, font-family, 'pingfang-regular');
    font-weight: 300;
    margin-top: 0.24rem;
    width: 100%;
    line-height: 0.32rem;
    .label {
      font-size: 0.26rem;
      // font-family: 'pingfang-semibold';
      // @include -media($pc, font-family, 'pingfang-semibold');
      font-weight: 500;
      margin-bottom: 0.1rem;
    }

    @media (max-width: $pad) {
      padding: 0.32rem 0;
      border-bottom: solid 1.5px #282644;
    }
  }

  .desc-container {
    font-size: 0.22rem;
    // font-family: 'pingfang-regular';
    // @include -media($pc, font-family, 'pingfang-regular');
    font-weight: 300;
    margin-top: 0.24rem;
    width: 100%;
    line-height: 0.32rem;
    @include -margin-bottom(0.2rem, 0.8rem, 0.8rem);
    &.blindBox {
      margin-bottom: 0.2rem;
    }
    .label {
      font-size: 0.26rem;
      // font-family: 'pingfang-semibold';
      // @include -media($pc, font-family, 'pingfang-semibold');
      font-weight: 500;
      margin-bottom: 0.1rem;
    }
    .tip {
      margin-top: 0.5rem;
    }

    @media (max-width: $pad) {
      padding: 0.32rem 0;
      border-bottom: solid 1.5px #282644;
      margin-bottom: 0.32rem;
    }
  }
}

footer {
  width: 100%;
  @media (min-width: $pad) {
    @include flexPos(flex-start, center);
  }
}

.footer-detail {
  @media (max-width: $pad) {
    @include flexPos(space-between);
    border-top: solid 1.5px #282644;
    padding-top: 0.28rem;
    margin-top: 0.36rem;
  }
  .title {
    font-size: 0.3rem;
  }

  .price-detail-content {
    @include flexPos(space-between);

    .icon-coin {
      font-size: 0.35rem;
    }
  }

  .mob-price-container {
    @include flexPos(center);

    .price-content {
      font-size: 0.48rem;
      line-height: 0.56rem;
      margin: 0 0.04rem 0 0.06rem;
    }
    .market-coins {
      width: 0.48rem;
      height: 0.48rem;
    }
    .doller {
      text-align: left;
      font-size: 0.28rem;
      line-height: 0.33rem;
    }
  }
}

.btns {
  @include flexPos(center);
  @media (max-width: $pad) {
    @include flexPos(space-around, center);
    margin-top: 0.72rem;
  }

  :deep(button) {
    width: 3.14rem;
    height: 0.81rem;
    background: #5b3bb7;
    border-radius: 100px;
    font-size: 0.36rem;
    // margin-left: 0.68rem;
    cursor: pointer;

    @media (max-width: $pad) {
      width: 2.96rem;
      height: 0.7rem;
      margin: 0;
      display: flex;
      border-radius: 0.06rem;
      justify-content: center;
      align-items: center;
      font-size: 0.28rem;
    }
  }
}

// 合约地址信息
.contract-container {
  font-size: 0.22rem;
  min-width: 3.45rem;
  line-height: 0.35rem;
  @include -margin-top(0, 0, 0.2rem);

  > div {
    @media (max-width: $pad) {
      @include flexPos(space-between);
    }

    > div {
      @include flexPos(flex-start);
    }

    .iconfont {
      font-size: 0.24rem;
      margin-left: 0.06rem;
      cursor: pointer;
    }
  }
}

.btn-wrap {
  width: 100%;
  flex-wrap: wrap;
  @include flexPos(flex-start, flex-start);
  @include -media($pad, justify-content, center); // pc 居中
  @include -media($phone, justify-content, center); // phone 左对齐
  // @include -margin-top(0.5rem, 0.8rem, 1.3rem);
  .btn {
    @include -width(2.96rem, 2rem, 2rem);
    @include -height(0.7rem, 0.62rem, 0.62rem);
    background: #5b3bb7;
    @include -font-size(0.24rem, 0.24rem, 0.28rem);
    @include -media($phone, margin-top, 0.4rem);
    @include -media($phone, border-radius, 6px);
    border-radius: 63px;
    @include -margin-right(0.3rem, 0.65rem, 0.65rem);
    margin-top: 0.1rem;
    cursor: pointer;

    &.disable {
      background: #a6a6a6;
    }
  }
}

// lease content
.lease-wrap {
  width: 100%;
  @include flexPos(flex-start, flex-start);
  @include -media($pad, justify-content, center); // pc 居中
  @include -media($pad, flex-direction, column); // 下排
  @include -media($phone, justify-content, center); // phone 左对齐
  @include -media($phone, flex-direction, column); // phone 左对齐
  .coin-wrap {
    flex-grow: 1;
    @include flexPos(flex-end, flex-end);
    flex-direction: column;
    margin-right: 0.4rem;
    @include -media($pad, width, 100%); // pad full width
    @include -media($phone, width, 100%); // phone full width
    @include -height(0.8rem, 0.62rem, 0.62rem);
    .coin {
      @include -font-size(0.22rem, 0.22rem, 0.22rem);
    }
    .val {
      @include -font-size(0.18rem, 0.16rem, 0.16rem);
    }
  }
  .lease-btn-wrap {
    flex-wrap: wrap;
    @include flexPos(flex-start, flex-start);
    @include -media($pad, justify-content, center); // pc 居中
    @include -media($phone, justify-content, center); // phone 左对齐
    .btn {
      @include -width(2.96rem, 2rem, 2rem);
      @include -height(0.7rem, 0.62rem, 0.62rem);
      background: #5b3bb7;
      @include -font-size(0.24rem, 0.24rem, 0.28rem);
      @include -media($phone, margin-top, 0.4rem);
      @include -media($phone, border-radius, 6px);
      border-radius: 63px;
      @include -margin-right(0.3rem, 0.65rem, 0.65rem);
      margin-top: 0.1rem;
      cursor: pointer;
      :deep(.el-loading-mask) {
        border-radius: 63px;
      }
    }
    .cutdown {
      background-color: #4e4e4e !important;
    }
  }
}

.tip-wrap {
  width: 100%;
  @include flexPos(center, flex-start);
  flex-direction: column;
  margin-top: 0.5rem;
  @include -font-size(0.18rem, 0.18rem, 0.18rem);
  .tip {
    line-height: 0.21rem;
    margin-top: 0.2rem;
  }
}

.img-wrap {
  @include flexPos(center);
  flex-direction: column;

  .img {
    width: 2.8rem;
    height: 3.7rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

// 父母属性
.parent-attrs-container {
  width: 5.14rem;
  margin-top: 0.7rem;
  .head {
    font-size: 0.5rem;
  }

  .title {
    font-size: 0.4rem;
    margin: 0.24rem 0;
  }

  .mother-attr {
    margin-top: 0.67rem;
  }
}
</style>
<style>
.container-section .van-popup__close-icon {
  font-size: 0.32rem;
  color: #fff;
  top: 5%;
  right: 3%;
}
</style>
