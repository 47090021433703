import { ref, reactive, readonly } from 'vue';

const usePopState = reactive({
  showPop: false, // 弹出层
  showStake: false // 质押弹窗
});

// /**
//  * 设置是否显示弹出层
//  */
// function setShowPop(payload) {
//   usePopState.showPop = payload;
// }

/**
 * 设置是否展示弹窗
 */
 const isShowPop = ref(false);
 export const showPop = readonly(isShowPop);
 export function setShowPop(payload) {
   isShowPop.value = payload;
 }

/**
 * 设置是否展示质押弹窗
 */
const isShowStakePop = ref(false);
export const showStakePop = readonly(isShowStakePop);
export function setShowStake(payload) {
  isShowStakePop.value = payload;
}

/**
 * 设置是否展示开启盲盒弹窗
 */
 const isShowOpenBoxPop = ref(false);
 export const showOpenBoxPop = readonly(isShowOpenBoxPop);
 export function setShowOpenBox(payload) {
  isShowOpenBoxPop.value = payload;
 }

/**
 * 是否显示盲盒弹窗
 */
const isShowBoxPop = ref(false);
export const showBoxPop = readonly(isShowBoxPop);
/**
 * 设置是否显示盲盒弹窗
 */
export function setShowBoxPop(val) {
  isShowBoxPop.value = val;
}

export { usePopState };
