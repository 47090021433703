import { ref, reactive, computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useBlindBoxStore from '@contractStore/blindBoxStore';
import { OPEN_IGO_BOX_CONT, BINANCE_BOX_CONT, OPEN_BINANCE_BOX_CONT, IGO_BLIND_BOX_CONT } from '@/contracts/polygonAddress';
import { showPop, showOpenBoxPop, setShowPop, setShowOpenBox } from '@cps/PopUp/usePop.js';
import $load from '@cps/GlobalLoading';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';
const $t = i18n.global.t;

export const hasAllowed = ref(false);
export const msg = ref('');
export const boxImg = ref('');
export const isClickConfirm = ref(false);

export function useContent() {
  const route = useRoute();
  const blindBoxStore = useBlindBoxStore();
  /**
   * 授权所有的盲盒類型
   */
  async function handleApproveforAll() {
    const showType = route.query.type;
    $load({ isShow: true });
    // 授权
    if (hasAllowed.value === false) {
      try {
        if (showType === 'binance_box') {
          console.log('show...', showType, OPEN_BINANCE_BOX_CONT.address);
          hasAllowed.value = await blindBoxStore.authAll(showType, OPEN_BINANCE_BOX_CONT.address);
        } else {
          hasAllowed.value = await blindBoxStore.authAll(showType, OPEN_IGO_BOX_CONT.address);
        }
        ElMessage({
          message: $t('msg.3'),
          type: 'success'
        });
      } catch (error) {
        console.log('approve..', error);
        ElMessage({
          message: $t('msg.4'),
          type: 'error'
        });
      }
    }
    $load({ isShow: false });
    return;
  }

  /**
   * 開啟盲盒彈窗
   * @returns 
   */
  async function handleOpen() {
    if (hasAllowed.value === false) return;
    if (hasAllowed.value === true && isClickConfirm.value === false) {
      isClickConfirm.value = true;
      return;
    }
    $load({ isShow: true, style: 'openBox' });
    const id = route.query.id;
    const showType = route.query.type;
    

    // 不同盲盒的合约地址及topic值，用于过滤事件
    let eventAddress, eventTopics;
    if (showType === 'Mystery-Box') {
      // IGO 初始盲盒
      eventAddress = IGO_BLIND_BOX_CONT.address;
      eventTopics = '0x66b10d9ff37b599d2105c94c297dc6149f44b851df44d93080dc0833ba8e6e91';
    } else if (showType === 'binance_box') {
      // HALO 盲盒
      eventAddress = BINANCE_BOX_CONT.address;
      eventTopics = '0x66b10d9ff37b599d2105c94c297dc6149f44b851df44d93080dc0833ba8e6e91';
    }
    try {
      // 开盲盒
      const resp = await blindBoxStore.openBox(id, showType);
      // 根据不同盲盒的合约地址及topic过滤事件
      const rewardEvent = resp.events.find(event => {
        return event.topics[0] === eventTopics;
      });
      const rewardInfo = getRewardType(rewardEvent?.topics);
      if (rewardInfo) {
        ElMessage({
          message: $t('blindBox.53') + ` ${rewardInfo?.amount} ` + $t(rewardInfo?.label),
          type: 'success'
        });
        boxImg.value = rewardInfo.image;
        msg.value = $t('blindBox.53') + ` ${rewardInfo?.amount} ` + $t(rewardInfo?.label);
        setShowOpenBox(false);
        setShowPop(true);
      }
    } catch (error) {
      console.log('open error..', error);
      ElMessage({
        message: $t('open error'),
        type: 'error'
      });
    }
    $load({ isShow: false });
  }

  // 开启盲盒的事件结果
  function getRewardType(event) {
    const tid = +event[2];
    const event3 = +event[3];
    const showType = route.query.type;
    const type = [
      {
        id: 1,
        label: 'box',
        name: 'box',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/box_ticket.png'
      },
      {
        id: 2,
        label: 'hero',
        name: 'hero',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/creation.png'
      },
      {
        id: 3,
        label: 'explanet',
        name: 'explanet',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/exPlanet.png'
      },
      {
        id: 4,
        label: 'homeplanet',
        name: 'homeplanet',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/homePlanet.png'
      },
      {
        id: 5,
        label: 'cattle',
        name: 'cattle',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/normal.png'
      },
      {
        id: 6,
        label: 'FAMG',
        name: 'shard',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png'
      },
      {
        id: 0,
        label: 'BVG',
        name: 'bvg',
        amount: 0,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/ticket/box_ticket.png'
      },
      {
        id: 10004,
        label: 'card.3',
        name: 'Bovine-Hero',
        amount: 1,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/boxgif/4.gif'
      },
      {
        id: 10003,
        label: 'card.4',
        name: 'Cattle',
        amount: 1,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/boxgif/3.gif'
      },
      {
        id: 10002,
        label: 'card.5',
        name: 'Items-Pack',
        amount: 1,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/boxgif/2.gif'
      },
      {
        id: 10001,
        label: 'card.6',
        name: 'Skins-Pack',
        amount: 1,
        token: 1,
        image: 'https://game.legendranch.app/legendaryranch/boxgif/1.gif'
      }
    ];

    const findItem = type.find(it => it.id === tid);
    const obj = { ...findItem };
    if (showType === 'HALO-BOX') {
      obj.amount = event3;
    } else if (showType === 'Mystery-Box') {
      obj.token = event3;
    } else if (showType === 'binance_box') {
      obj.token = event3;
    }
    console.log('reward..', obj);
    return obj;
  }

  return { handleApproveforAll, handleOpen };
}

/**
 * 不足两位数的前面补0
 */
export function _toTwo(num) {
  return num.toString().length === 2 ? num : '0' + num;
}
