<!-- 轉賬{{$t('dialog.6')}}弹窗 -->
<script setup>
import { defineProps, inject, watchEffect, ref } from 'vue';

import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import useCattleStore from '@contractStore/cattleStore';
import { useAppStore } from '@store/appStore';
import { pickCardType, setShowTransferPop } from '../../useCardPackage.js';
import commonObj from '@/contracts/commonContract.js';
import itemsObj from '@/contractsApi/polygon/ItemVoucher';
import $load from '@cps/GlobalLoading';
import { getChainData } from '@/utils/tools.js';
import {
  BINANCE_BOX_CONT,
  BV_BLIND_BOX_CONT,
  IGO_BLIND_BOX_CONT,
  CATTLE_CONT,
  FRAGMENT_CONT,
  HALO_BLIND_BOX_CONT,
  IGO_BOX_EXCHANGE_CONT
} from '@/contracts/polygonAddress.js';
import i18n from '@/utils/i18n';
const $t = i18n.global.t;

const appStore = useAppStore();
const $route = useRoute();
const $router = useRouter();

const inpVal = ref('');
const itemsType = ['shard', 'items'];

/**
 * 处理购买数量
 * @param {String} isAdd true: 加，false: 减
 */
const transferNum = ref(1);
function handleNum(isAdd) {
  if (isAdd && transferNum.value < 20) {
    transferNum.value += 1;
  }

  if (!isAdd && transferNum.value > 1) {
    transferNum.value -= 1;
  }
}

/**
 * 转账确认
 */
async function handleTransfer(address) {
  const type = $route.query.type;
  const { ethers,baseGasPrice,provider } = appStore.ethObj;
  const allowType = [
    'halo_box',
    'mark_box',
    'creation_ticket',
    'normal_ticket',
    'homeland_ticket',
    'genland_ticket'
  ];
  let comContract = null;
  if ($route.query.cate === 'hero') {
    // 创世牛
    const { contract } = new commonObj(CATTLE_CONT);
    comContract = contract;
  } else if (type === 'Mystery-Box') {
    // IGO盲盒
    const { contract } = new commonObj(IGO_BLIND_BOX_CONT);
    comContract = contract;
  } else if (type === 'shard') {
    // halo 开出来的碎片
    const { contract } = new commonObj(FRAGMENT_CONT);
    comContract = contract;
  } else if (allowType.includes(type)) {
    // halo 宝箱的兑换券
    const { contract } = new commonObj(HALO_BLIND_BOX_CONT);
    comContract = contract;
  } else if (type === 'items') {
    // 游戏的道具兑换券
    const { contract } = new itemsObj();
    comContract = contract;
  } else if (
    type === 'Skins-Pack' ||
    type === 'Items-Pack' ||
    type === 'Bovine-Hero' ||
    type === 'Cattle'
  ) {
    // oat 的皮肤 bsc链
    if (appStore.ethObj.chainId === '0x38') {
      const { contract } = new commonObj(BV_BLIND_BOX_CONT);
      comContract = contract;
    } else if (appStore.ethObj.chainId === '0x89') {
      const { contract } = new commonObj(IGO_BOX_EXCHANGE_CONT);
      comContract = contract;
    }
  } else if (type === 'binance_box') {
    // 幣安的盲盒
    const { contract } = new commonObj(BINANCE_BOX_CONT);
    comContract = contract;
  }
  if (!ethers.utils.isAddress(address)) {
    ElMessage({
      message: $t('msg.33'),
      type: 'error'
    });
    return;
  }
  $load({ isShow: true });

  try {

    if (itemsType.includes(type)) {
      const res = await comContract.safeTransferFrom(
        appStore.defaultAccount,
        address,
        type === 'items' ? $route.query.id : 1,
        transferNum.value,
        '0x00'
      );
      await res?.wait();
    } else {
      let transactionCount = await provider.getTransactionCount(appStore.defaultAccount);
      // console.log('transactionCount',transactionCount)
      // let transactionCount = await provider.getTransactionCount(appStore.defaultAccount);
      // console.log('transactionCount',transactionCount)
      const limit = await comContract.estimateGas.transferFrom(
        appStore.defaultAccount, address, $route.query.id,
        {
          from: appStore.defaultAccount,
        }
        );
      // console.log('limit:s%,baseGasPrice:s%,transactionCount:%s',limit.toString(),baseGasPrice,transactionCount)
      const res = await comContract.transferFrom(appStore.defaultAccount, address, $route.query.id,{
        nonce: transactionCount+1,
        gasLimit: (Number(limit) * 1.5).toFixed(0),
        gasPrice: 10 * 10 ** 9 + baseGasPrice,
      });
      await res?.wait();
    }

    ElMessage({
      message: $t('msg.17'),
      type: 'success'
    });
    $load({ isShow: false });
    setShowTransferPop(false);
    pickCardType($route.query.cate);
    $router.push({ name: 'cardPackage' });
  } catch (error) {
    console.log('erroer..', error);
    let info = error?.data?.message || error?.message;
    // 点击了拒绝信息
    if (info?.includes?.('User denied transaction')) {
      info = 'User denied transaction signature.';
    }
    // 移动端点击了拒绝信息
    if (error?.includes?.('cancel')) {
      info = 'User denied transaction signature.';
    }
    // 移动端报错信息
    if (error?.reason) {
      info = error?.reason;
    }
    // 避免信息太长看懵用户
    info = String(info).length > 100 ? $t('msg.18') : info;
    ElMessage({
      message: info,
      type: 'error'
    });
  }
  $load({ isShow: false });
}

/**
 * PC 取消弹窗
 * phone 返回上一页
 */
function handleCancel() {
  inpVal.value = '';
  if (appStore.curDevice === 'phone') {
    $router.back();
  } else {
    setShowTransferPop(false);
  }
}

function getAliasName() {
  if (appStore.ethObj.chainId === '0x38') {
    return 'BSC';
  } else if (appStore.ethObj.chainId === '0x89') {
    return 'Polygon';
  }
  return '';
}
</script>

<template>
  <div class="transfer-pop-wrap">
    <header>{{ $t('detail.23') }}</header>
    <!-- 购买数量 -->
    <div class="block-wrap" v-show="itemsType.includes($route.query.type)">
      <div class="label">{{ $t('igo.2') }}</div>
      <div class="btn-warp">
        <button class="btn sub" @click="handleNum(false)">-</button>
        <input type="number" placeholder="1" class="buy-num" v-model="transferNum" />
        <button class="btn plus" @click="handleNum(true)">+</button>
      </div>
    </div>
    <div class="tips">
      {{ $t('detail.24', ['']) }}
    </div>
    <input type="text" class="inp" v-model="inpVal" />
    <!-- 按钮 -->
    <div class="btn-wrap">
      <button @click="handleTransfer(inpVal)">{{ $t('dialog.1') }}</button>
      <button @click="handleCancel()">
        {{ appStore.curDevice === 'phone' ? $t('dialog.3') : $t('dialog.6') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transfer-pop-wrap {
  width: 9.38rem;
  height: auto;
  @include -media($phone, width, 100%);
  @include -media($phone, height, 100vh);
  @include -media($phone, border-radius, 0);
  background-image: linear-gradient(360deg, #402866 0%, #000 100%);
  padding: 0.24rem 0.4rem 0.5rem;
  border: solid 0.01rem rgba(255, 255, 255, 0.3);
  border-radius: 0.1rem;
  color: #fff;
}

header {
  font-size: 0.4rem;
  margin-bottom: 0.08rem;
  @include -media($phone, font-size, 0.6rem);
  @include -media($phone, margin-bottom, 0.8rem);
}
.block-wrap {
  display: flex;
  flex-direction: column;
  @include -font-size(0.24rem, 0.24rem, 0.24rem);
  .label {
    font-size: 0.24rem;
    margin-bottom: 0.25rem;
  }

  .num {
    font-size: 0.48rem;
    font-weight: bold;
    color: #fffc2a;
    text-align: flex-end;
  }

  .btn-warp {
    @include flexPos(flex-start, center);
    flex-direction: row;
    background-color: transparent;

    .btn {
      width: 0.4rem;
      height: 0.4rem;
      @include flexPos(center);
      border: 1px solid #fff;
    }

    &.sub {
      border-radius: 3px 0px 0px 3px;
      border-right: none;
    }

    &.plus {
      border-radius: 0 3px 3px 0;
      border-left: none;
    }
    .buy-num {
      width: 1.2rem;
      text-align: center;
      background: transparent;
      border: none;
    }
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tips {
  font-size: 0.24rem;
  margin-top: 0.46rem;
  @include -media($phone, font-size, 0.28rem);
  @include -media($phone, margin-bottom, 0.28rem);
}

.inp {
  width: 100%;
  height: 0.6rem;
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: 0.22rem;
  padding-left: 0.12rem;
  font-size: 0.22rem;
}

.btn-wrap {
  margin: 0.82rem auto 0.06rem;
  width: 100%;
  @include flexPos(space-around, center);
  button {
    width: 1.57rem;
    height: 0.49rem;
    border-radius: 0.25rem;
    font-size: 0.22rem;
    font-weight: 500;
    background-color: #5b3bb7;
    // border: solid 1px #fff;
    @include -media($phone, width, 2.96rem);
    @include -media($phone, height, 0.7rem);
    @include -media($phone, border-radius, 0.06rem);
    @include -media($phone, font-size, 0.28rem);
    cursor: pointer;
  }
}
</style>
