<!-- 卡包详情页 -->
<script setup>
import { computed, reactive, ref, provide, watch, watchEffect } from 'vue';
import NavPanel from '../components/NavPanel.vue';
import NFTDetail from './components/ContentComp.vue';
import TransferPop from './components/Transfer.vue';
import { useAppStore } from '@store/appStore';
import {
  showPanel,
  setShowNavPanel,
  showTransferPop,
  curCardType,
  pickCardType
} from '../useCardPackage.js'; // 卡包
import i18n from '@/utils/i18n';
const $t = i18n.global.t;

const appStore = useAppStore();

/**
 * 获取牛牛详情
 */
watchEffect(async () => {
  if (appStore.defaultAccount == null) return;
  setShowNavPanel(false);
  // await init();
});

</script>

<template>
  <div :class="['nft-market-wrap', { mob: appStore.curDevice !== 'pc' }]">
    <!-- 移动端的侧边栏，滑动弹出面板 -->
    <transition name="market-panel">
      <div class="panel-container" v-show="appStore.curDevice !== 'pc' && showPanel">
        <NavPanel />
      </div>
    </transition>

    <transition name="market-main">
      <section class="main" v-if="appStore.curDevice !== 'pc' && !showPanel">
        <!-- 容器 -->
        <section class="container-section">
          <NFTDetail />
        </section>
      </section>
    </transition>

    <!-- 内容区 -->
    <section class="main" v-if="appStore.curDevice === 'pc'">
      <!-- 侧边选择面板 -->
      <aside>
        <NavPanel />
      </aside>

      <!-- 商品列表 -->
      <section class="container-section">
        <NFTDetail />
      </section>
    </section>
  </div>

  <!-- 弹窗 -->
  <van-popup v-model:show="showTransferPop">
    <TransferPop />
  </van-popup>
</template>

<style lang="scss" scoped>
.panel-container {
  position: fixed;
  @include -media($phone, left, 0.5rem);
  top: $mobTopBarHeight;
  width: 100%;
  overflow: auto;
  padding-bottom: 4.36rem;

  .btns {
    margin-bottom: 0 !important;

    button {
      width: 100% !important;
    }
  }
}

.nft-market-wrap {
  $navHeight: 1.5rem; // tab导航栏高度
  $asideWidth: 3.64rem; // 侧边栏宽度

  @include nftImgBg;

  &.mob {
    border-bottom: solid 0.01px transparent;
    height: calc(100vh - $mobTopBarHeight);
  }

  color: #fff;

  > .main {
    display: flex;
    height: 100%;

    > aside {
      flex: 0 0 $asideWidth;
      margin-right: 0.7rem;
      // height: 100vh;
      // overflow: auto;
      border-right: solid 1px #3f354f;
    }

    > .container-section {
      flex: auto;
      // padding-left: 1.26rem;
      overflow: auto;
    }

    @media screen and (max-width: 1440px) {
      > .container-section {
        padding-left: 0;

        :deep(.sale-num) {
          font-size: 0.3rem;
        }
      }
    }
  }

  // 呼出面板按钮
  .btns {
    @include flexPos(center);
    padding: 0 0.48rem;
    position: relative;

    button {
      width: 4.96rem !important;
      height: 0.7rem;
      background-color: #5b3bb7;
      border-radius: 0.06rem;
      font-size: 0.28rem;
      position: absolute;
      bottom: -2.5rem;
    }

    // margin-bottom: 4.7rem;
  }
}
</style>
