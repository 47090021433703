<!-- 钱包侧边栏面板 -->
<script setup>
import { $GET } from '@/service/require.js';
import { ref, reactive, getCurrentInstance, computed, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore.js';
import useMarketStore from '@store/cattleMarketStore.js';
import useCattleStore from '@contractStore/cattleStore.js';
import useCattleBadgeStore from '@contractStore/cattleBadgeStore.js';
import usePlanetStore from '@contractStore/planetStore.js';
import usePlanetLeaseStore from '@contractStore/planetLeaseStore.js';
import {
  setShowNavPanel,
  curNavType,
  sideNavs,
  pickSideNav,
  setCurNavType,
  cardTypes,
  pickCardType,
  curCardType,
} from '../useCardPackage.js';
import $load from '@cps/GlobalLoading';
import { plusXing } from '@/utils/tools';
import useBlindBoxStore from '@contractStore/blindBoxStore.js';
import useOATMintStore from '@contractStore/oatMintStore.js';

const gThis = getCurrentInstance().appContext.config.globalProperties;

const route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const packageStore = useMarketStore();
const planetStore = usePlanetStore();
const cattleStore = useCattleStore();
const cattleBadgeStore =  useCattleBadgeStore();
const blindBoxStore = useBlindBoxStore();
const oatMintStore = useOATMintStore();
const accStore = useAccountStore();
const planetLeaseStore = usePlanetLeaseStore();

planetStore.createContract();
cattleStore.createContract();
cattleBadgeStore.createContract();
oatMintStore.createContract();
planetLeaseStore.createContract();
packageStore.heroList = [];
packageStore.cattleList = [];
planetStore.planetList = [];
blindBoxStore.blindBoxList = [];
/**
 * 默认头像 or 用户头像
 */
const tempImg = require('../../../assets/img/home/logo.png')//'https://game.legendranch.app/legendaryranch/picture/profile/0.png';
const avatar = computed(() => {
  return !accStore.avatar ? tempImg : accStore.avatar;
});

const unionImg = 'https://game.legendranch.app/legendaryranch/picture/guildicon/0.png';
const union = computed(() => {
  return !accStore.planetImg ? unionImg : accStore.planetImg;
});
/**
 * 初始化
 */
async function init() {
  try {
    if (curNavType.value === 'nft') {
      if (route.name === 'cardPackage') {
        await handlePickCardType(curCardType.value);
      }
    }
  } catch (error) {
    console.log(error);
  }
}
watchEffect(async () => {
  if (!appStore.defaultAccount) return;
  init();
});

/**
 * 切换选择头像页面
 */
async function handleEditAvart() {
  setCurNavType('avatar');
  if (appStore.curDevice !== 'pc') {
    setShowNavPanel(false);
  }
}

/**
 * 选择侧边栏导航
 */
pickSideNav(curNavType.value);
function handlePickSideNav(val) {
  try {
    pickSideNav(val);
  } catch (error) {
    console.log(error);
  }

  if (appStore.curDevice !== 'pc') {
    setShowNavPanel(false);
  }
  router.push({ name: 'cardPackage' });
}

/**
 * 选择卡牌类型并获取数据
 */
async function handlePickCardType(val) {
  // console.log('curCardType.value',curCardType.value)
  // 选择卡牌类型
  pickCardType(val);
  console.log('cardType..', curCardType.value);
  // 获取数据
  $load({ isShow: true });
  let tokenids = [];
  try {
    if (curCardType.value === 'hero') {
      // 创世牛牛
      tokenids = await cattleStore.getCattleIds(appStore.defaultAccount);
      await packageStore.fetchCattleArray(curCardType.value, tokenids.slice(0, 20));
      console.log('hero...', tokenids, packageStore.heroList);
      packageStore.totalNum = tokenids.length;
    } else if (curCardType.value === 'cattle') {
      // 普通牛牛
      tokenids = await cattleStore.getCattleIds(appStore.defaultAccount, false);
      await packageStore.fetchCattleArray(curCardType.value, tokenids.slice(0, 20));
      console.log('cattle...', tokenids, packageStore.cattleList);
      packageStore.totalNum = tokenids.length;
    } else if (curCardType.value === 'planet') {
      // 星球
      await planetStore.getPlanetList(appStore.defaultAccount);
      packageStore.totalNum = planetStore.planetList.length;
    } else if (curCardType.value === 'lease') {
      // 星球租賃
      await planetLeaseStore.getPlanetLeaseList();
      packageStore.totalNum = 1;
    } else if (curCardType.value === 'blindBox') {
      // 盲盒
      await blindBoxStore.getAllBoxList(appStore.defaultAccount);
      packageStore.totalNum = blindBoxStore.blindBoxList.length;
      blindBoxStore.setBlindBoxList(blindBoxStore.blindBoxList.slice(0, 20));
    } else if (curCardType.value === 'cattleBadge') {
      // 牛牛徽章
      await cattleBadgeStore.getCattleBadgeList(appStore.defaultAccount);
      packageStore.totalNum = 0;
    } else if (curCardType.value === 'others') {
      // oat 徽章
      // await oatMintStore.getUserOATList();
      // 二開盲盒
      await blindBoxStore.getOtherVoucherList(appStore.defaultAccount);
      packageStore.totalNum = blindBoxStore.blindBoxList.length;
      blindBoxStore.setBlindBoxList(blindBoxStore.blindBoxList.slice(0, 20));
    }
  } catch (error) {
    console.log('handlePickCardType',error);
  }
  $load({ isShow: false });
}
</script>

<template>
  <div class="listing-panel-wrap">
    <!-- 头像以及个人信息 -->
    <div class="avatar-wrap">
      <img :src="avatar" alt="" class="avatar" @click="handleEditAvart" />
      <div class="addr">
        <div class="content">{{ plusXing(accStore.spaceName, 6, 6) }}</div>
        <i
          :data-clipboard-text="appStore.defaultAccount"
          class="iconfont icon-cpy cpy-btn"
          v-show="appStore.defaultAccount"
        ></i>
      </div>
    </div>
    <!-- 侧面导航栏 -->
    <div
      :class="['navs', { active: nav.active && curNavType === nav.name }]"
      v-for="nav in sideNavs"
      :key="nav.id"
      @click="handlePickSideNav(nav.name)"
    >
      <div :class="['title', { active: nav.active, 'bottom-line': nav.name === 'account' }]">
        {{ $t(nav.label) }}
      </div>
      <!-- 卡牌类型列表 -->
      <template v-if="nav.name === 'nft'">
        <div class="list-container">
          <li
            :class="[{ active: item.active && curNavType === 'nft' }]"
            @click="()=>handlePickCardType(item.name)"
            v-for="item in cardTypes"
            :key="item.id"
          >
            {{ $t(item.label) }}
          </li>
        </div>
      </template>
    </div>
    <!-- 工会 -->
    <div class="union-wrap">
      <img :src="union" alt="" />
      <span class="label">{{ accStore.planetName }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing-panel-wrap {
  padding: 0.64rem 0 0 0;
  @include -media($pad, display, flex) {
    @include flexPos('center');
  }
  flex-direction: column;
  // background-color: aqua;
}

.avatar-wrap {
  @include -media($phone, display, flex) {
    @include flexPos(center);
  }
  @include -media($pad, display, flex) {
    @include flexPos(center);
  }
  flex-direction: column;
  @include -margin-left(0, 0, 0.65rem);
  .avatar {
    @include -width(2.33rem, 2.33rem, 1.4rem);
    @include -height(2.33rem, 2.33rem, 1.4rem);
    border-radius: 50%;
    border: solid 0.03rem #fff;
    @include -margin-left(0, 0, 0.2rem);
  }
  .addr {
    margin: 0.23rem 0.2rem 0;
    @include flexPos(flex-start);
    .content {
      margin-right: 0.1rem;
      @include -font-size(0.28rem, 0.18rem, 0.18rem);
    }

    .icon-cpy {
      @include -font-size(0.28rem, 0.18rem, 0.18rem);
      transform: translateY(-0.03rem);
    }
  }
}

.navs {
  @include -media($phone, padding-left, 0.5rem);
  cursor: pointer;
  &.active {
    color: #fff;
  }

  .title {
    @include -padding-left(0, 0, 0.8rem);
    @include -font-size(0.48rem, 0.48rem, 0.32rem);
    // font-family: 'pingfang-semibold' !important;
    // @include -media($pc, font-family, 'pingfang-semibold');
    font-weight: bold;
    margin-top: 0.5rem;
    color: #a6a6a6;

    &.active {
      display: flex;
      text-align: left;
      align-items: center;
      font-weight: bold;
      height: 0.54rem;
      color: #fff;
      @include -media($pc, background, linear-gradient(90deg, #402866 0%, rgba(0, 0, 0, 0) 100%));
    }
  }

  .bottom-line {
    @include -padding-left(0, 0, 0.8rem);
    @include -font-size(0.6rem, 0.48rem, 0.32rem);

    line-height: 0.37rem;
    margin-top: 0.5rem;
    position: relative;
    &::after {
      content: '';
      display: none;
      @include -media($pc, display, block);
      height: 1px;
      background-color: #fff;
      width: 1.88rem;
      position: absolute;
      @include -left(0, 0, 0.8rem); // left:0.8rem
      bottom: -0.25rem;
    }
  }

  .list-container {
    // border-bottom: solid 1px #fff;
    position: relative;
    @include -padding-left(0, 0, 1.24rem);
    @include -padding-right(0, 0, 0.28rem);

    &::after {
      content: '';
      display: none;
      @include -media($pc, display, block);
      height: 1px;
      background-color: #fff;
      width: 1.88rem;
      position: absolute;
      @include -left(0, -0.4rem, 0.8rem); // left:0.8rem
      bottom: -0.25rem;
      left: 0;
    }

    > li {
      font-size: 0.28rem;
      // font-family: 'pingfang-regular' !important;
      // @include -media($pc, font-family, 'pingfang-regular');
      font-weight: 200;
      @include -margin-top(0.5rem, 0.28rem, 0.28rem);
      color: #a6a6a6;
      cursor: pointer;
      position: relative;
      &::after {
        content: '';
        display: block;
        @include -media($pc, display, none);
        height: 1px;
        background-color: rgba($color: #fff, $alpha: 0.2);
        width: 90%;
        position: absolute;
        left: 0;
        top: 0.6rem;
      }
      &:last-child::after {
        display: none;
      }

      &.active {
        // font-family: 'pingfang-regular';
        // @include -media($pc, font-family, 'pingfang-regular');
        font-weight: 500;
        color: #fff;
      }
    }
  }

  .list-container-mob {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    justify-content: space-between;
    align-items: center;

    li {
      text-align: center;
      width: 1.86rem;
      height: 0.52rem;
      line-height: 0.52rem;
      font-size: 0.24rem;
      // font-family: 'pingfang-regular';
      // @include -media($pc, font-family, 'pingfang-regular');
      border: solid 0.02rem #fff;
      border-radius: 0.04rem;
      margin-top: 0.28rem;
      cursor: pointer;
      &.active {
        // font-family: 'pingfang-redium';
        // @include -media($pc, font-family, 'pingfang-regular');
        font-weight: bold;
        background-color: #5b3bb7;
        border: solid 0.02rem transparent;
      }
    }
  }
}
.union-wrap {
  @include -width(2rem, 100%, 1.72rem);
  @include flexPos(center);
  flex-direction: column;
  margin: 0.2rem auto;
  @include -margin-left(auto, 0, 0.75rem);
  img {
    @include -width(2rem, 2rem, 1.6rem);
  }
  .label {
    color: #fff;
    @include -font-size(0.36rem, 0.36rem, 0.28rem);
    margin-top: 0.1rem;
  }
}
</style>
