<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import { showOpenBoxPop, setShowOpenBox } from './usePop';

const props = defineProps({
  title: {
    type: String,
    default: 'blindBox.48'
  },
  stepTitle: {
    type: Array,
    default: ['blindBox.49', 'blindBox.51']
  },
  stepDesc: {
    type: Array,
    default: [
      'blindBox.50',
      'blindBox.52'
    ]
  },
  isApprove: {
    type: Boolean,
    default: false
  },
  isStake: {
    type: Boolean,
    default: false
  }
});

const $emits = defineEmits(['approve', 'open']);

// 纵向线
const line = computed(() => {
  return props.isApprove ? '0' : '1px';
});

/**
 * 关闭按钮
 */
function handleClose() {
  setShowOpenBox(false);
}
</script>

<template>
  <van-popup v-model:show="showOpenBoxPop" closeable @click-close-icon="handleClose">
    <div class="pop-wrap">
      <slot name="title">
        <div class="head-wrap">
          <span>{{ $t(props.title) }}</span>
        </div>
      </slot>

      <ul>
        <li class="active">
          <div class="content-wrap">
            <div class="label">{{ $t(props.stepTitle[0]) }}</div>

            <el-collapse-transition>
              <div v-show="!props.isApprove" class="box-content">
                <p>
                  {{ $t(props.stepDesc[0]) }}
                </p>
                <button @click="$emits('approve')">
                  <i class="iconfont icon-lock"></i>{{ $t('dialog.8') }}
                </button>
              </div>
            </el-collapse-transition>
          </div>
          <img
            :src="
              !props.isApprove
                ? require('@img/common/icon-inactive.svg')
                : require('@img/common/icon-finish.svg')
            "
            alt=""
            class="icon"
          />
        </li>
        <li class="active">
          <div class="content-wrap">
            <div class="label">{{ $t(props.stepTitle[1]) }}</div>

            <div class="box-content">
              <el-collapse-transition
                ><p v-show="!props.isStake">{{ $t(props.stepDesc[1]) }}</p></el-collapse-transition
              >

              <button @click="$emits('open')" :class="!props.isApprove ? 'unstake' : ''">
                {{ !props.isStake ? $t('dialog.1') : $t('dialog.12') }}
              </button>
            </div>
          </div>
          <img
            :src="
              !props.isStake
                ? require('@img/common/icon-inactive.svg')
                : require('@img/common/icon-finish.svg')
            "
            alt=""
            class="icon"
          />
        </li>
      </ul>
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
.pop-wrap {
  position: relative;
  @include -width(auto, 80vh, 9.18rem);
  @include flexPos(center);
  flex-direction: column;
  font-size: 0.24rem;
  font-weight: 500;
  color: #fff;
  padding: 0.48rem 0.72rem 0.48rem 0.64rem;
  border-radius: 10px;
  border: 1px solid rgba(#fff, 0.5);
  backdrop-filter: blur(0.3rem);
  // @include -media()
  background: linear-gradient(to top, #402866, rgba($color: #3f00a7, $alpha: 0));
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.head-wrap {
  width: 100%;
  @include flexPos(flex-start, center);
  font-size: 0.36rem;
  font-weight: bold;
  // margin-bottom: 0.48rem;

  .icons {
    font-size: 0.5rem;
    padding: 0 0.3rem;
    color: #7ae8fc;
  }
}

.trans {
  transform: rotate(180deg);
}

.confirm-btn {
  width: 2.9rem;
  height: 0.7rem;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include flexPos(center);
  color: #6298db;
  font-size: 0.24rem;
  background-image: url('~@img/nft/bg-confirm-btn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.icon {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.1rem;
}

ul {
  list-style: none; /* 取消默认样式 */
  display: flex; /* 使用flex */
  counter-reset: steps;
  @include flexPos(flex-start, flex-start);
  flex-direction: column;
}
ul li {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 0.53rem;
  // align-items: center;
}

ul li::before {
  width: 0.54rem;
  height: 0.54rem;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 0 0.2rem 0 0;
  display: block;
  counter-increment: steps;
  content: counter(steps);

  /* 使 步骤数字水平垂直居中，并设置字号 */
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 0.36rem;
  font-weight: 500;
  position: relative;

  z-index: 1;
}

ul li:nth-child(n + 2)::after {
  content: '';
  height: 90%;
  width: v-bind(line);
  background: #fff;
  position: absolute;
  left: 4%;
  top: -10%;
  @include -media($phone, left, 5%);
  @include -media($phone, top, -15%);
  transform: translate(-50%, -100%);
}

ul li div {
  color: #fff;
}
ul li div:before {
  // content: '第' counter(steps) '步';
  color: inherit;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
}

ul li.active:nth-child(n + 2)::after {
  // background: #00bc9b;
}

ul li.active::before {
  // background: #00bc9b;
  // box-shadow: 0 0 0 0.5rem rgb(255 255 255), 0 0 0 1rem #00bc9b;
}

.content-wrap {
  @include -width(3.8rem, vw(780), 6rem);
  color: #fff;
  margin: 0.1rem 0.5rem 0 0;
  @include flexPos(flex-start, flex-start);
  flex-direction: column;
  .label {
    font-size: 0.36rem;
  }

  .box-content {
    width: 100%;
    color: #fff;
    @include flexPos(center);
    flex-direction: column;
    p {
      width: 100%;
      margin-top: 0.15rem;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  i {
    font-size: 0.18rem;
    margin-right: 0.1rem;
  }

  button {
    width: 2.3rem;
    height: 0.58rem;
    color: #fff;
    @include flexPos(center);
    margin-top: 0.48rem;
    border-radius: 45rem;
    background-color: #8626ff;
  }

  .unstake {
    color: #b9b9b9;
    background-color: rgba($color: #999999, $alpha: 1);
  }
}
</style>
