<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import { showPop, setShowPop } from './usePop';
import { useRoute, useRouter } from 'vue-router';
const $router = useRouter();

const props = defineProps({
  title: {
    type: String,
    default: 'SUCCESS'
  },
  stepTitle: {
    type: Array,
    default: ['Approve NFT', 'Confirm opening']
  },
  stepDesc: {
    type: Array,
    default: [
      'We’ll ask your approval to access your token. This is a one-time only operation.',
      'Confirm to open your mystery box'
    ]
  },
  isApprove: {
    type: Boolean,
    default: false
  }
});

const $emits = defineEmits(['approve', 'open']);

/**
 * 关闭按钮
 */
function handleClose() {
  setShowPop(false);
  $router.push({
    name: 'cardPackage'
  });
}
</script>

<template>
  <van-popup v-model:show="showPop" closeable @click-close-icon="handleClose">
    <div class="pop-wrap">
      <div class="head-wrap">
        <span>{{ $t(props.title) }}</span>
      </div>

      <slot name="content"> This address has 8 pre-order spots </slot>

      <slot name="btns">
        <div class="confirm-btn" @click="handleClose">Confirm</div>
      </slot>
    </div>
  </van-popup>
</template>

<style lang="scss" scoped>
.pop-wrap {
  position: relative;
  @include -width(90vw, 80vh, 8.18rem);
  // @include -height(auto, 70vh, 7.65rem);
  @include flexPos(flex-start, center);
  flex-direction: column;
  font-size: 0.24rem;
  font-weight: 500;
  color: #fff;
  // padding: 0.48rem 0.3rem 0.6rem;
  padding: 0.48rem 0.72rem 0.48rem 0.64rem;
  border-radius: 10px;
  border: 1px solid rgba(#fff, 0.5);
  backdrop-filter: blur(0.3rem);
  background: linear-gradient(to top, #402866, rgba($color: #3f00a7, $alpha: 0));
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.head-wrap {
  @include flexPos(flex-start, center);
  font-size: 0.36rem;
  font-weight: bold;
  margin-bottom: 0.3rem;

  .icons {
    font-size: 0.5rem;
    padding: 0 0.3rem;
    color: #7ae8fc;
  }
}

.trans {
  transform: rotate(180deg);
}

.confirm-btn {
  width: 2.3rem;
  height: 0.58rem;
  color: #fff;
  @include flexPos(center);
  margin: 0.38rem 0;
  border-radius: 45rem;
  background-color: #8626ff;
  // width: 2.9rem;
  // height: 0.7rem;
  // position: absolute;
  // bottom: 10%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // @include flexPos(center);
  // color: #6298db;
  // font-size: 0.24rem;
  // background-image: url('~@img/nft/bg-confirm-btn.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  cursor: pointer;
}

.icon {
  width: 0.6rem;
  height: 0.6rem;
}

.content-wrap {
  width: 3.8rem;
  color: #fff;
  margin: 0 0.5rem;
  @include flexPos(flex-start, flex-start);
  flex-direction: column;
  .label {
    font-size: 0.4rem;
  }

  .content {
    color: #fff;
    @include flexPos(center);
    flex-direction: column;
  }

  i {
    font-size: 0.18rem;
    margin-right: 0.1rem;
  }

  button {
    width: 2.3rem;
    height: 0.58rem;
    color: #fff;
    @include flexPos(center);
    margin-top: 0.48rem;
    border-radius: 45rem;
    background-color: #8626ff;
  }

  .unstake {
    color: #b9b9b9;
    background-color: rgba($color: #999999, $alpha: 1);
  }
}
</style>
