import { computed, reactive, ref, watchEffect, readonly } from 'vue';
import { padNumber } from '@/utils/tools.js';
import { useRoute, useRouter } from 'vue-router';
import useCattleStore from '@contractStore/cattleStore';
import useCattleBadgeStore from '@contractStore/cattleBadgeStore';
import useMarketStore from '@store/cattleMarketStore';
import usePlanetStore from '@contractStore/planetStore';
import useBlindBoxStore from '@contractStore/blindBoxStore';
import useOATMintStore from '@contractStore/oatMintStore.js';
import usePlanetLeaseStore from '@contractStore/planetLeaseStore';
import { avatarEmits, ElMessage } from 'element-plus';
import {
  CATTLE_CONT,
  BLIND_BOX_CONT as POLYGON_BLIND_BOX_CONT,
  IGO_BLIND_BOX_CONT,
  OAT_NFT_CONT,
  BINANCE_BOX_CONT,
  STAR_CONT,
  HALO_BLIND_BOX_CONT,
  FRAGMENT_CONT,
  IGO_BOX_EXCHANGE_CONT,
  VOUCHER_CONT,
  CATTLE_BADGE_COUNT
} from '@/contracts/polygonAddress';
import dayjs from 'dayjs';
import i18n from '@/utils/i18n';
const $t = i18n.global.t;

export function useCardDetail() {
  const route = useRoute();
  const showCate = ref(route.query.cate);
  const showType = ref(route.query.type);
  const planetStore = usePlanetStore();
  const blindBoxStore = useBlindBoxStore();
  const oatMintStore = useOATMintStore();
  const marketStore = useMarketStore();
  const cattleStore = useCattleStore();
  const cattleBadgeStore = useCattleBadgeStore()
  const planetLeaseStore = usePlanetLeaseStore();

  /**
   * 设置牛牛详情信息
   */
  const cattleAttrInfo = ref({
    cls: 0,
    parents: [0, 0],
    isAdult: 0,
    gender: 0,
    life: 0,
    growth: 0,
    energy: 0,
    attack: 0,
    stamina: 0,
    defense: 0,
    milk: 0,
    milkRate: 0
  });
  const tokenId = ref(1);
  const cattleParents = ref([]);
  const cattleDetailInfo = ref({});
  async function setCattleDetailInfo(id) {
    tokenId.value = id;

    cattleDetailInfo.value = await marketStore.fetchCattleInfo(id);
    await cattleStore.getCattleInfo(id);
    cattleAttrInfo.value = cattleStore.cattleInfo;
    const { parents } = cattleAttrInfo.value;
    cattleParents.value = parents;
  }

  /**
   * 设置盲盒父母
   */
  function setBlindBoxParents(val) {
    cattleParents.value = val;
  }

  /**
   * 牛牛是否成年
   */
  const cisAdult = computed(() => {
    const { class: cls, isAdult } = cattleDetailInfo.value;
    if (cls == 1) {
      return true;
    } else if (cls === 2) {
      return isAdult;
    }
    return true;
  });
  const isAdultCattle = readonly(cisAdult);

  /**
   * 牛牛的名称
   */
  const cname = computed(() => {
    // const { name, class: cls, gender } = cattleDetailInfo.value;
    console.log('cattleDetailInfo ...', cattleDetailInfo.value, cattleDetailInfo.value.class);
    if (cattleDetailInfo.value.class === 1) {
      return `${$t('card.3')} #${tokenId.value}`;
    } else if (cattleDetailInfo.value.class === 2) {
      return `${$t('card.4')} #${tokenId.value}`;
    }
    return 'N/A';
  });
  const cattleName = readonly(cname);

  /**
   * 牛牛的簡介
   */
  const cdesc = computed(() => {
    const $tx = useI18n();
    const { name, class: cls, gender } = cattleDetailInfo.value;
    if (cls === 1) {
      return `${$t('card.34')}`;
    } else if (cls === 2) {
      return `${$t('card.35')}`;
    }
    return 'N/A';
  });
  const cattleDesc = readonly(cdesc);

  /**
   * 牛牛的图片
   */
  const cimg = computed(() => {
    const { image } = cattleDetailInfo.value;
    return image;
  });
  const cattleImg = readonly(cimg);

  /**
   * 牛牛的死亡时间
   */
  const cdateTime = computed(() => {
    const { deadTime } = cattleAttrInfo.value;
    const { class: cls } = cattleDetailInfo.value;
    if (cls === 1) {
      return null;
    } else if (cls === 2) {
      return deadTime;
    }
    return null;
  });
  const cattleDeadLine = readonly(cdateTime);

  // 基础属性
  const basicAttr = reactive({
    class: 'detail.2',
    gender: '',
    info: [
      {
        name: 'detail.5',
        eName: 'life',
        value: '-'
      },
      {
        name: 'detail.6',
        eName: 'growth',
        value: '-'
      },
      {
        name: 'detail.7',
        eName: 'str',
        value: '-'
      }
    ]
  });

  // 战斗属性
  const battleAttr = reactive({
    class: 'detail.3',
    info: [
      {
        name: 'detail.8',
        eName: 'attack',
        value: '-'
      },
      {
        name: 'detail.9',
        eName: 'stamina',
        value: '-'
      },
      {
        name: 'detail.10',
        eName: 'defence',
        value: '-'
      }
    ]
  });

  // 产奶属性
  const milkAttr = reactive({
    class: 'detail.4',
    info: [
      {
        name: 'detail.11',
        eName: 'milk',
        value: '-'
      },
      {
        name: 'detail.12',
        eName: 'milk-rate',
        value: '-'
      }
    ]
  });

  // 最终要展示的属性
  const finalAttr = computed(() => {
    const {
      cls,
      parents,
      isAdult,
      gender,
      life,
      growth,
      energy,
      attack,
      stamina,
      defense,
      milk,
      milkRate
    } = cattleAttrInfo.value;
    if (new Set(parents).has(0)) {
      basicAttr.parents = null; // 父母id
    } else {
      basicAttr.parents = parents; // 父母id
    }
    // basicAttr.parents = parents; // 父母id
    basicAttr.gender = gender; // 性别
    basicAttr.info[0].value = life; // 生命值
    basicAttr.info[1].value = growth; // 成长值
    basicAttr.info[2].value = energy; // 体力值

    // 公牛
    if (gender === 1) {
      battleAttr.info[0].value = attack.toFixed(0); // 攻击
      battleAttr.info[1].value = stamina.toFixed(0); // 耐力
      battleAttr.info[2].value = defense.toFixed(0); // 防御

      return [basicAttr, battleAttr];
    }
    // 母牛
    if (gender === 2) {
      milkAttr.info[0].value = milk.toFixed(0);
      milkAttr.info[1].value = milkRate.toFixed(0);

      return [basicAttr, milkAttr];
    }

    return [];
  });

  const cattleAttr = readonly(finalAttr);

  /**
   * 是否存在父母
   */
  const hasParentsId = ref(false);
  const hasCattleParents = computed(() => {
    return !hasParentsId.value && cattleParents.value?.length;
  });
  const hasParents = readonly(hasCattleParents);

  watchEffect(async () => {
    hasParentsId.value = new Set(cattleParents.value).has(0);
    // console.log('parents..', hasParents.value, cattleParents.value, cattleParents.value?.length);
    if (hasParents.value) {
      await getAllParentData();
    }
  });

  async function getAllParentData() {
    // 构造 父母属性 数组
    await Promise.all([
      getParentDetailInfo(cattleParents.value[0]),
      getParentDetailInfo(cattleParents.value[1])
    ]).then(values => {
      values.forEach(item => {
        if (item?.gender == 1) {
          bullAttr.value = integrateData(item);
        } else if (item?.gender == 2) {
          cowAttr.value = integrateData(item);
        }
      });
      // console.log('parent...', bullAttr.value, cowAttr.value);
    });
  }

  /**
   * 公牛属性
   */
  const bullAttr = ref(null);
  const fatherInfo = computed(() => {
    return bullAttr.value;
  });
  const fatherAttr = readonly(fatherInfo);

  /**
   * 母牛属性
   */
  const cowAttr = ref(null);
  const motherInfo = computed(() => {
    return cowAttr.value;
  });
  const motherAttr = readonly(motherInfo);

  /**
   * 获取父母的信息
   */
  const parentDetailInfo = ref({});
  async function getParentDetailInfo(id) {
    const cattleStore = useCattleStore();
    const infos = await cattleStore.getCattleInfo(id);
    return infos;
  }

  /**
   * 整合属性 深拷贝
   * @param {Object} infos
   * @returns Array
   */
  function integrateData(infos) {
    const { gender, life, growth, energy, attack, stamina, defense, milk, milkRate } = infos;
    var _ = require('lodash');
    const tempBasicAttr = _.cloneDeep(basicAttr);
    tempBasicAttr.gender = gender; // 性别
    tempBasicAttr.info[0].value = life; // 生命值
    tempBasicAttr.info[1].value = growth; // 成长值
    tempBasicAttr.info[2].value = energy; // 体力值

    // 公牛
    const tempBattleAttr = _.cloneDeep(battleAttr);
    if (gender === 1) {
      tempBattleAttr.info[0].value = attack; // 攻击
      tempBattleAttr.info[1].value = stamina; // 耐力
      tempBattleAttr.info[2].value = defense; // 防御
      return [tempBasicAttr, tempBattleAttr];
    }
    // 母牛
    const tempMilkAttr = _.cloneDeep(milkAttr);
    if (gender === 2) {
      tempMilkAttr.info[0].value = milk;
      tempMilkAttr.info[1].value = milkRate;
      return [tempBasicAttr, tempMilkAttr];
    }

    return [];
  }

  /**
   * 内容名称
   */
  const cardName = computed(() => {
    // 星球
    if (showCate.value === 'planet') {
      return $t(planetStore.planetInfo?.name || 'undefined');
    } else if (showCate.value === 'lease') {
      // 星球租賃
      return $t('card.12');
    } else if (showCate.value === 'hero' || showCate.value === 'cattle') {
      // 牛
      return cattleName.value;
    } else if (showType.value === 'halo_box') {
      // Halo盲盒
      return $t('card.31');
    } else if (showType.value === 'mark_box') {
      // mart盲盒
      return $t('card.18');
    } else if (showCate.value === 'blindBox') {
      // 盲盒
      return $t(blindBoxStore.blindBoxInfo?.name || 'undefined');
    } else if (showCate.value === 'cattleBadge') {
      // 牛牛徽章
      return $t(cattleBadgeStore.cattleBadgeInfo?.name || 'undefined');
    } else if (showCate.value === 'others') {
      // oat
      if (showType.value === 'oat') {
        return oatMintStore.cardInfo?.name;
      } else if (
        showType.value === 'Bovine-Hero' ||
        showType.value === 'Cattle' ||
        showType.value === 'Items-Pack' ||
        showType.value === 'Skins-Pack'
      ) {
        return $t(blindBoxStore.blindBoxInfo?.name || 'undefined');
      } else {
        return $t(blindBoxStore.blindBoxInfo?.name || 'undefined');
      }
    } else {
      return 'N/A';
    }
  });

  /**
   * 图片
   */
  const cardImg = computed(() => {
    // 牛牛
    if (showCate.value === 'hero' || showCate.value === 'cattle') {
      return cattleDetailInfo.value.image;
    } else if (showCate.value === 'planet') {
      // 星球
      return planetStore.planetInfo.image;
    } else if (showCate.value === 'lease') {
      // 星球租賃
      return 'https://game.legendranch.app/legendaryranch/planet/homeland.png';
    } else if (showType.value === 'halo_box') {
      // Halo盲盒
      return 'https://game.legendranch.app/legendaryranch/ticket/haloBox.png';
    } else if (showType.value === 'mark_box') {
      // mart盲盒
      return 'https://game.legendranch.app/legendaryranch/ticket/markBox.png';
    } else if (showCate.value === 'blindBox') {
      // 盲盒
      return blindBoxStore.blindBoxInfo?.image;
    } else if (showCate.value === 'others') {
      // oat bv
      if (showType.value === 'oat') {
        return oatMintStore.cardInfo?.image;
      } else if (
        showType.value === 'Bovine-Hero' ||
        showType.value === 'Cattle' ||
        showType.value === 'Items-Pack' ||
        showType.value === 'Skins-Pack'
      ) {
        return blindBoxStore.blindBoxInfo?.image;
      } else {
        return blindBoxStore.blindBoxInfo?.image;
      }
    } else {
      return 'https://s3.bmp.ovh/imgs/2022/01/eac36c3b71fe903c.png';
    }
  });

  /**
   * 卡牌简介
   */
  const cardDesc = computed(() => {
    if (showCate.value === 'planet') {
      // 星球
      return $t(planetStore.planetInfo?.desc || 'undefined');
    } else if (showCate.value === 'lease') {
      // 星球租賃
      return $t('card.15');
    } else if (showCate.value === 'hero') {
      // 创世
      return $t('card.34');
    } else if (showCate.value === 'cattle') {
      // 牛
      return $t('card.35');
    } else if (showType.value === 'halo_box') {
      // Halo盲盒
      return $t('card.32');
    } else if (showType.value === 'mark_box') {
      // mart盲盒
      return $t('card.24');
    } else if (showCate.value === 'blindBox') {
      // 盲盒
      return $t(blindBoxStore.blindBoxInfo?.desc || 'undefined');
    } else if (showCate.value === 'cattleBadge') {
      // 牛牛徽章
      return '--' //$t(cattleBadgeStore.cattleBadgeInfo?.desc || 'undefined');
    } else if (showCate.value === 'others') {
      // oat
      if (showType.value === 'oat') {
        return $t(oatMintStore.cardInfo?.desc || 'undefined');
      } else if (
        showType.value === 'Bovine-Hero' ||
        showType.value === 'Cattle' ||
        showType.value === 'Items-Pack' ||
        showType.value === 'Skins-Pack'
      ) {
        return $t(blindBoxStore.blindBoxInfo.desc);
      } else {
        return $t(blindBoxStore.blindBoxInfo.desc);
      }
    }
  });

  /**
   * 合约地址
   */
  const contractAddr = computed(() => {
    if (showCate.value === 'planet') {
      // 星球
      return STAR_CONT.address;
    } else if (showCate.value === 'lease') {
      // 星球租賃
      return STAR_CONT.address;
    } else if (showCate.value === 'hero' || showCate.value === 'cattle') {
      // 牛
      return CATTLE_CONT.address;
    } else if (showType.value === 'blindBox') {
      // 盲盒
      return POLYGON_BLIND_BOX_CONT.address;
    } else if (showType.value === 'halo_box') {
      // 盲盒
      return HALO_BLIND_BOX_CONT.address;
    } else if (showType.value === 'mark_box') {
      // mart盲盒
      return HALO_BLIND_BOX_CONT.address;
    } else if (showType.value === 'Mystery-Box') {
      // IGO盲盒
      return IGO_BLIND_BOX_CONT.address;
    } else if (
      showType.value === 'Skins-Pack' ||
      showType.value === 'Items-Pack' ||
      showType.value === 'Bovine-Hero' ||
      showType.value === 'Cattle'
    ) {
      // IGO 二开盲盒
      return IGO_BOX_EXCHANGE_CONT.address;
    } else if (showType.value === 'Halo-Box') {
      // halo
      return HALO_BLIND_BOX_CONT.address;
    } else if (showType.value === 'binance_box') {
      // 币安盲盒
      return BINANCE_BOX_CONT.address;
    } else if (showType.value === 'oat') {
      // oat
      return OAT_NFT_CONT.address;
    } else if (showType.value === 'items') {
      // 游戏道具兑换券
      return VOUCHER_CONT.address;
    } else if (showType.value === 'normal_ticket') {
      // 普通牛兑换券
      return HALO_BLIND_BOX_CONT.address;
    } else if (showCate.value === 'cattleBadge') {
      // 牛牛徽章
      return CATTLE_BADGE_COUNT.address;
    } else if (showCate.value === 'others') {
      // 兑换券
      return FRAGMENT_CONT.address;
    } else {
      return '';
    }
  });

  return {
    hasParents,
    cattleName,
    cardName,
    cardImg,
    cardDesc,
    contractAddr,
    cattleDesc,
    cattleImg,
    cattleAttr,
    fatherAttr,
    motherAttr,
    cattleDeadLine,
    isAdultCattle,
    cattleDetailInfo,
    setBlindBoxParents,
    setCattleDetailInfo
  };
}
